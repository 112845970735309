import { Box, Button, Paper, Typography, Link as MuiLink } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Chessboard from "./Chessboard";
import { getPuzzleDetails } from "./chessPuzzleContract";
import { DEFAULT_CONTRACT_ADDRESS, EXPLORER_URL } from "./consts";
import Header from "./Header";
import { useProvider } from "./ProviderContext";
import FaucetButton from "./FaucetButton";
import SolveRandomPuzzleButton from "./SolveRandomPuzzleButton";

const ChessboardPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const chessPuzzleAddress =
    searchParams.get("contract") || DEFAULT_CONTRACT_ADDRESS;
  const [message, setMessage] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [puzzleDetails, setPuzzleDetails] = useState(null);
  const { provider, error } = useProvider();
  const fen = decodeURIComponent(
    location.pathname.replace("/puzzle/", "")
  ).replace(/_/g, " ");

  useEffect(() => {
    console.log("URL Params:", { fen, chessPuzzleAddress });
    const fetchPuzzleDetails = async () => {
      if (provider) {
        try {
          const puzzle = await getPuzzleDetails(
            provider,
            chessPuzzleAddress,
            fen
          );
          console.log("Puzzle Details:", puzzle);
          setPuzzleDetails(puzzle);
          setIsActive(puzzle.isActive);
        } catch (error) {
          console.error("Error fetching puzzle details:", error);
          setIsActive(false);
          setMessage("Error fetching puzzle details.");
        }
      }
    };

    if (error) {
      setMessage(error);
    } else if (chessPuzzleAddress) {
      fetchPuzzleDetails();
    } else {
      setMessage("Contract address not provided in URL.");
    }
  }, [provider, chessPuzzleAddress, error, fen, location.pathname]);

  return (
    <Box sx={{ textAlign: "center", alignItems: "center", mt: 4, mb: 2, p: 2 }}>
      <FaucetButton />
      <Header contractAddress={chessPuzzleAddress} />
      {message && (
        <Typography variant="h6" sx={{ mb: 2 }}>
          {message}
        </Typography>
      )}
      {!isActive ? (
        <Typography variant="h5" sx={{ mb: 2 }}>
          Puzzle is not found in the contract (probably it solved already)
        </Typography>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Chessboard fen={fen} chessPuzzleAddress={chessPuzzleAddress} />
        </Box>
      )}
      {puzzleDetails && puzzleDetails.reward && puzzleDetails.symbol && (
        <Box sx={{ mt: 2, p: 2 }}>
          <Typography variant="body1">
            Reward: {puzzleDetails.reward} {puzzleDetails.symbol} (
            {puzzleDetails.tokenAddress})
          </Typography>
        </Box>
      )}

      <SolveRandomPuzzleButton contractAddress={chessPuzzleAddress} />
    </Box>
  );
};

export default ChessboardPage;
