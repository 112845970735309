import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useProvider } from "./ProviderContext";
import { Box, Typography, Button } from "@mui/material";
import { getPuzzles } from "./chessPuzzleContract";
import { DEFAULT_CONTRACT_ADDRESS } from "./consts";
import FaucetButton from "./FaucetButton";
import Header from "./Header";

const PuzzlesPage = () => {
  const [message, setMessage] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const chessPuzzleAddress =
    searchParams.get("contract") || DEFAULT_CONTRACT_ADDRESS;
  const { provider, error, connectWallet, account } = useProvider();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAndNavigateToRandomPuzzle = async () => {
      if (provider && account) {
        try {
          const unsolvedPuzzles = await getPuzzles(
            provider,
            chessPuzzleAddress
          );
          if (unsolvedPuzzles.length > 0) {
            const randomPuzzle =
              unsolvedPuzzles[
                Math.floor(Math.random() * unsolvedPuzzles.length)
              ];
            navigate(
              `/puzzle/${randomPuzzle.replace(
                / /g,
                "_"
              )}?contract=${chessPuzzleAddress}`
            );
          } else {
            setMessage("No unsolved puzzles available.");
          }
        } catch (error) {
          console.error("Error fetching puzzles:", error);
          setMessage("Error fetching puzzles.");
        }
      }
    };

    if (error) {
      setMessage(error);
    } else if (!account) {
      setMessage("Please connect your wallet to see the puzzles.");
    } else if (chessPuzzleAddress) {
      fetchAndNavigateToRandomPuzzle();
    } else {
      setMessage("Contract address not provided in URL.");
    }
  }, [provider, chessPuzzleAddress, error, account, navigate]);

  return (
    <Box
      className="puzzles-page"
      sx={{
        textAlign: "center",
        mt: 4,
        mb: 2,
        p: 2,
        minHeight: "100vh",
        pb: 8,
      }}
    >
      <FaucetButton />
      <Header contractAddress={chessPuzzleAddress} />
      {!account && (
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#6540ff",
            color: "#fff",
            fontSize: "1.25rem",
            padding: "16px 32px",
            "&:hover": { backgroundColor: "#4a31cc" },
            mb: 4,
            fontWeight: "bold",
          }}
          onClick={connectWallet}
        >
          Connect Wallet
        </Button>
      )}
      {message && (
        <Typography variant="h6" className="message">
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default PuzzlesPage;
