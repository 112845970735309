import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProvider } from "./ProviderContext";
import { getPuzzles } from "./chessPuzzleContract";
import { DEFAULT_CONTRACT_ADDRESS } from "./consts";

const SolveRandomPuzzleButton = ({
  contractAddress = DEFAULT_CONTRACT_ADDRESS,
}) => {
  const [puzzles, setPuzzles] = useState([]);
  const [message, setMessage] = useState("");
  const { provider, error, account } = useProvider();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPuzzles = async () => {
      if (provider && account) {
        try {
          const unsolvedPuzzles = await getPuzzles(provider, contractAddress);
          setPuzzles(unsolvedPuzzles);
          setMessage(""); // Clear the message when puzzles are fetched
        } catch (error) {
          console.error("Error fetching puzzles:", error);
          setMessage("Error fetching puzzles.");
        }
      }
    };

    if (error) {
      setMessage(error);
    } else if (!account) {
      setMessage("Please connect your wallet to see the puzzles.");
    } else if (contractAddress) {
      fetchPuzzles();
    } else {
      setMessage("Contract address not provided.");
    }
  }, [provider, contractAddress, error, account]);

  const handleSolvePuzzleClick = () => {
    if (puzzles.length > 0) {
      const randomPuzzle = puzzles[Math.floor(Math.random() * puzzles.length)];
      navigate(
        `/puzzle/${randomPuzzle.replace(/ /g, "_")}?contract=${contractAddress}`
      );
    }
  };

  return (
    <>
      {message && <Typography variant="h6">{message}</Typography>}
      {account &&
        (puzzles.length > 0 ? (
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#6540ff",
              color: "#fff",
              fontSize: "1rem",
              padding: "16px 32px",
              "&:hover": { backgroundColor: "#4a31cc" },
              fontWeight: "bold",
            }}
            onClick={handleSolvePuzzleClick}
          >
            New Game
          </Button>
        ) : (
          <Typography variant="h6">All puzzles solved</Typography>
        ))}
    </>
  );
};

export default SolveRandomPuzzleButton;
