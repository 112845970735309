import React from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Box } from "@mui/material";
import SolveRandomPuzzleButton from "./SolveRandomPuzzleButton";

const Header = ({ contractAddress }) => {
  return (
    <Box sx={{ textAlign: "center", mt: 4 }}>
      <Link
        to={`/?contract=${contractAddress}`}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <Typography
              variant="h4"
              className="title"
              sx={{ fontWeight: "bold", cursor: "pointer" }}
            >
              ♟️ Chess Puzzles
            </Typography>
          </Grid>
        </Grid>
      </Link>
      <Typography variant="subtitle1" className="subtitle" sx={{ mt: 1 }}>
        Deployed on Fluent Developer Preview
      </Typography>

    </Box>
  );
};

export default Header;
