import React, { createContext, useContext, useEffect, useState } from "react";
import { BrowserProvider } from "ethers";

const ProviderContext = createContext(null);

export const useProvider = () => useContext(ProviderContext);

export const Provider = ({ children }) => {
  const [provider, setProvider] = useState(null);
  const [error, setError] = useState(null);
  const [account, setAccount] = useState(
    localStorage.getItem("account") || null
  );

  const checkNetwork = async (browserProvider) => {
    const chainId = await browserProvider.send("eth_chainId", []);
    if (Number(chainId) === 20993 || Number(chainId) === 1337) {
      return true;
    }
    try {
      await browserProvider.send("wallet_switchEthereumChain", [
        { chainId: "0x5201" },
      ]);
    } catch (e) {
      await browserProvider.send("wallet_addEthereumChain", [
        {
          blockExplorerUrls: ["https://blockscout.dev.thefluent.xyz/"],
          iconUrls: ["https://avatars.githubusercontent.com/u/115495687"],
          nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18,
          },
          "rpcUrls": [
            "https://rpc.dev.thefluent.xyz/"
          ],
          "chainId": "0x5201",
          "chainName": "Fluent Developer Preview"
        }
      ]);
    }
    return false;
  };

  const connectWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        const browserProvider = new BrowserProvider(window.ethereum);
        await checkNetwork(browserProvider);
        const accounts = await browserProvider.send("eth_requestAccounts", []);
        setProvider(browserProvider);
        setAccount(accounts[0]);
        localStorage.setItem("account", accounts[0]);
        console.log("Connected account:", accounts[0]); // Add logging
      } catch (err) {
        setError("Failed to initialize provider");
        console.error(err);
      }
    } else {
      setError("MetaMask is not installed");
    }
  };

  useEffect(() => {
    const reconnectWallet = async () => {
      if (account && typeof window.ethereum !== "undefined") {
        try {
          const browserProvider = new BrowserProvider(window.ethereum);
          if (!(await checkNetwork(browserProvider))) {
            window.location.reload();
          }
          const accounts = await browserProvider.send("eth_accounts", []);
          if (accounts.includes(account)) {
            setProvider(browserProvider);
            console.log("Reconnected account:", account); // Add logging
          } else {
            localStorage.removeItem("account");
            setAccount(null);
          }
        } catch (err) {
          setError("Failed to reconnect provider");
          console.error(err);
        }
      }
    };

    reconnectWallet();
  }, [account]);

  return (
    <ProviderContext.Provider
      value={{ provider, error, connectWallet, account }}
    >
      {children}
    </ProviderContext.Provider>
  );
};
