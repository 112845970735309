import React from "react";
import { Button } from "@mui/material";

const FaucetButton = () => {
  return (
    <Button
      variant="contained"
      sx={{
        position: 'fixed',
        bottom: 40,
        left: 16,
        backgroundColor: "#6540ff",
        color: "#fff",
        fontSize: '1rem',
        padding: '12px 24px',
        "&:hover": { backgroundColor: "#4a31cc" },
        zIndex: 1000,
        fontWeight: 'bold'
      }}
      onClick={() => window.open("https://faucet.dev.thefluent.xyz/", "_blank")}
    >
      Get Test ETH
    </Button>
  );
};

export default FaucetButton;
